import React from 'react';
import PropTypes from 'prop-types';
import Constants from 'constants/Constants';
import styled from 'styled-components';
import LoginStore from 'stores/LoginStore';
import EditableObjectHandle from '../Common/EditableObjectHandler';
import IntlObject from '../Common/IntlObject';

class Grid01 extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      authInfo: LoginStore.authInfo,
      options: this.props.siteSub.objects,
    };
    this.onChange = this.onChange.bind(this);
  }

  onChange() {
    this.setState({ authInfo: LoginStore.authInfo });
  }

  componentDidMount() {
    LoginStore.addChangeListener(this.onChange);
  }

  componentWillUnmount() {
    LoginStore.removeChangeListener(this.onChange);
  }

  render() {
    const options = this.state.options;
    if (!options) {
      return null;
    }

    const isLogin = this.state.authInfo
      ? this.state.authInfo.status === Constants.AUTHED
      : false;

    const GridContainer = styled.section`
      position: relative;
      margin-top: 50px;
      margin-bottom: 50px;
      ${options['word-keep-all'] ? 'word-break: keep-all;' : ''}
      & .grid-card {
        box-shadow: 1px 3px 5px 0px rgba(0, 0, 0, 0.15);
      }

      & .card-body {
        padding: 28px 35px;
      }

      & .card-title {
        color: var(--b, #232323);
        font-size: 28px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        letter-spacing: -0.8px;
      }

      & .card-text {
        color: #66676e;
        font-size: 14px;
        font-style: normal;
        font-weight: 350;
        letter-spacing: -0.7px;
      }
    `;

    const Title = styled.div`
      color: #000;
      text-align: center;
      font-size: 45px;
      font-style: normal;
      font-weight: 700;
      line-height: 1.2em;
      letter-spacing: -0.8px;
    `;

    const Subtitle = styled.div`
      color: #8e8e8e;
      text-align: center;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 24.4px;
      letter-spacing: -0.8px;
    `;

    const defultObjects = {
      'word-keepall': '0',
      grid01: {
        pic: 'https://via.placeholder.com/300x200',
      },
      grid02: {
        pic: 'https://via.placeholder.com/300x200',
      },
      grid03: {
        pic: 'https://via.placeholder.com/300x200',
      },
      grid04: {
        pic: 'https://via.placeholder.com/300x200',
      },
    };

    return (
      <GridContainer className={this.props.containerClass}>
        <EditableObjectHandle
          id={this.props.siteSub.id}
          objects={this.state.options}
          defaultObjects={defultObjects}
          isAdmin={isLogin}
        />
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 mb-4">
            <Title>
              <IntlObject keycode="grid01.title" isLogin={isLogin} />
            </Title>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8 mb-5">
            <Subtitle>
              <IntlObject keycode="grid01.subtitle" isLogin={isLogin} />
            </Subtitle>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-md-8">
            <div className="row">
              <div className="col-12 col-xl-6 mb-4">
                <div className="card grid-card h-100" data-aos="fade-up">
                  {options.grid01 && (
                    <img
                      src={options.grid01.pic}
                      className="card-img-top"
                      alt="..."
                    />
                  )}
                  <div className="card-body">
                    <h5 className="card-title">
                      <IntlObject
                        keycode="grid01.grid01.title"
                        isLogin={isLogin}
                      />
                    </h5>
                    <p className="card-text">
                      <IntlObject
                        keycode="grid01.grid01.content"
                        isLogin={isLogin}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 mb-4">
                <div
                  className="card grid-card h-100"
                  data-aos="fade-up"
                  data-aos-delay="50"
                >
                  {options.grid01 && (
                    <img
                      src={options.grid02.pic}
                      className="card-img-top"
                      alt="..."
                    />
                  )}
                  <div className="card-body">
                    <h5 className="card-title">
                      <IntlObject
                        keycode="grid01.grid02.title"
                        isLogin={isLogin}
                      />
                    </h5>
                    <p className="card-text">
                      <IntlObject
                        keycode="grid01.grid02.content"
                        isLogin={isLogin}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 mb-4">
                <div
                  className="card grid-card h-100"
                  data-aos="fade-up"
                  data-aos-delay="100"
                >
                  {options.grid01 && (
                    <img
                      src={options.grid03.pic}
                      className="card-img-top"
                      alt="..."
                    />
                  )}
                  <div className="card-body">
                    <h5 className="card-title">
                      <IntlObject
                        keycode="grid01.grid03.title"
                        isLogin={isLogin}
                      />
                    </h5>
                    <p className="card-text">
                      <IntlObject
                        keycode="grid01.grid03.content"
                        isLogin={isLogin}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-6 mb-4">
                <div
                  className="card grid-card h-100"
                  data-aos="fade-up"
                  data-aos-delay="150"
                >
                  {options.grid01 && (
                    <img
                      src={options.grid04.pic}
                      className="card-img-top"
                      alt="..."
                    />
                  )}
                  <div className="card-body">
                    <h5 className="card-title">
                      <IntlObject
                        keycode="grid01.grid04.title"
                        isLogin={isLogin}
                      />
                    </h5>
                    <p className="card-text">
                      <IntlObject
                        keycode="grid01.grid04.content"
                        isLogin={isLogin}
                      />
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </GridContainer>
    );
  }
}

Grid01.propTypes = {
  site: PropTypes.object.isRequired,
  siteSub: PropTypes.object.isRequired,
};

export default Grid01;
