import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeaderType01 from './HeaderType01';
import HtmlContainer from './HtmlContainer';
import PropertiesContainer from './PropertiesContainer';
import ContactUsContainer from './ContactUsContainer';
import VirtualShowing01 from './VirtualShowing01';
import FooterType01 from './FooterType01';
import FooterType02 from './FooterType02';
import LeftFooterMenuType01 from './LeftFooterMenuType01';
import Grid01 from './Grid01';
import Grid02 from './Grid02';
import HeaderType02 from './HeaderType02';
import List2ColumnsType01 from './List2ColumnsType01';
import OnePageMenuType01 from './OnePageMenuType01';

class SiteSubGenerator extends Component {
  constructor(props) {
    super(props);

    this.state = { site: this.props.site, siteSub: this.props.siteSub };

    this.getSiteSub = this.getSiteSub.bind(this);
  }

  getSiteSub(site, siteSub) {
    switch (siteSub.type) {
      case 'siteSub.header-01':
        return (
          <HeaderType01
            logo={site.logo}
            title={site.title || site.eName}
            subtitle={site.subtitle}
            sns={{
              facebook: site.facebook,
              instagram: site.instagram,
              youtube: site.youtube,
            }}
          />
        );
      case 'siteSub.header-02':
        return (
          <HeaderType02
            id={siteSub.id}
            options={siteSub.objects}
            sns={{
              facebook: site.facebook,
              instagram: site.instagram,
              youtube: site.youtube,
            }}
            brokerage={site.brokerage}
          />
        );
      case 'siteSub.html':
        return (
          <HtmlContainer
            id={siteSub.id}
            html={siteSub.html}
            style={siteSub.style}
            script={siteSub.script}
          />
        );
      case 'siteSub.properties-01':
        return <PropertiesContainer options={siteSub.objects} hasRibbon />;
      case 'siteSub.properties-02':
        return <PropertiesContainer options={siteSub.objects} />;
      case 'siteSub.contactus':
        return (
          <ContactUsContainer
            id={siteSub.id}
            options={siteSub.objects}
            phone={site.phone}
            googleFormLink={site.googleFormLink}
            formEndpoint={site.formEndpoint}
            portrait={siteSub.objects.portrait}
            backgroundImage={siteSub.objects.backgroundImage}
            padding={siteSub.objects.padding}
            margin={siteSub.objects.margin}
          />
        );
      case 'siteSub.virtualshowing-01':
        return (
          <VirtualShowing01
            phone={site.phone}
            options={siteSub.objects}
            propertyType="1"
            formEndpoint={site.formEndpoint}
          />
        );
      case 'siteSub.virtualshowing-02':
        return (
          <VirtualShowing01
            phone={site.phone}
            options={siteSub.objects}
            propertyType="2"
            formEndpoint={site.formEndpoint}
          />
        );
      case 'siteSub.footer-01':
        return <FooterType01 />;
      case 'siteSub.footer-02':
        return <FooterType02 />;
      case 'siteSub.left-fixed-menu-01':
        return (
          <LeftFooterMenuType01
            containerClass="d-none d-sm-none d-md-block"
            phone={site.phone}
            phoneAvailable={site.phoneAvailable}
            email={site.email}
            kakao={site.kakao}
            googleFormLink={site.googleFormLink}
          />
        );
      case 'siteSub.grid-01':
        return <Grid01 containerClass="container" siteSub={siteSub} />;
      case 'siteSub.grid-02':
        return <Grid02 containerClass="container" siteSub={siteSub} />;
      case 'siteSub.list-2column-01':
        return (
          <List2ColumnsType01 containerClass="container" siteSub={siteSub} />
        );
      case 'siteSub.onepage-menu-01':
        return <OnePageMenuType01 id={siteSub.id} options={siteSub.objects} />;
      default:
        return <div></div>;
    }
  }

  render() {
    return this.getSiteSub(this.state.site, this.state.siteSub);
  }
}
SiteSubGenerator.propTypes = {
  site: PropTypes.shape({
    id: PropTypes.string,
    memberId: PropTypes.string,
    createdAt: PropTypes.string,
    hasBackground: PropTypes.string,
    formEndpoint: PropTypes.string,
    googleFormLink: PropTypes.string,
    name: PropTypes.string,
    eName: PropTypes.string,
    phone: PropTypes.string,
    phoneAvailable: PropTypes.string,
    email: PropTypes.string,
    address: PropTypes.string,
    facebook: PropTypes.string,
    instagram: PropTypes.string,
    youtube: PropTypes.string,
    kakao: PropTypes.string,
  }),
  siteSub: PropTypes.shape({
    type: PropTypes.string,
    objects: PropTypes.string,
    html: PropTypes.string,
    style: PropTypes.string,
    script: PropTypes.string,
    order: PropTypes.number,
  }),
};

export default SiteSubGenerator;
